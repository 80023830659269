import React, { useRef, useEffect } from "react";
import ArcGISMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import esriConfig from "@arcgis/core/config";
//import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Expand from "@arcgis/core/widgets/Expand";
import Legend from "@arcgis/core/widgets/Legend";

import suppliersLayer from "./Constants/suppliersLayer";
import addSuppliersToLayer from "./Constants/suppliersGraphic";

import Select from 'react-select';


import suppliers from "../data/imap-export.json";
import countries from "../data/countries.json";

import "../App.css";
function SuppliersMap() {



  const mapDiv = useRef(null);
  const legendDiv=useRef(null);
  const infoDiv=useRef(null);

  esriConfig.apiKey = process.env.REACT_APP_ARCGIS_API_KEY
  console.log(esriConfig.apiKey );
  esriConfig.assetsPath = "./assets";

  useEffect(() => {
    if (mapDiv.current) {
    
      const map = new ArcGISMap({
          basemap: "arcgis-topographic"
      });

      const view = new MapView({
        map:map,
        container: mapDiv.current,
        center: [10, 35],
        zoom: 3,
      });

      //Add Supplier to Layer
      map.add(suppliersLayer);

      cleanupSuppliersLayer(suppliersLayer);
      
      showAllSuppliers(suppliersLayer);
  
      function showAllSuppliers(layer) {
        addSuppliersToLayer(layer,suppliers.suppliers);
        view.goTo({
          center: [10, 35],
          zoom: 3, // Zoom level
        });
      }
     
      function cleanupSuppliersLayer(layer) {
        layer.queryFeatures().then((results) => {
          applyDeleteGraphicsToLayer(layer, {
            deleteFeatures: results.features
          });
        });
      }
  
      function applyDeleteGraphicsToLayer(layer, deletions) {
        layer.applyEdits(deletions).then(results => {
          if (results.deleteFeatureResults.length > 0) {
            console.info(`Successfully deleted ${results.deleteFeatureResults.length} suppliers to the feature layer.`);
          }
        }).catch((error) => {
          console.error(`Error while deleting suppliers from the feature layer.`);
          console.error(error);
        });
      }
   
      function applyAddGraphicsToLayer(layer, additions) {
        layer.applyEdits(additions).then(results => {
          if (results.addFeatureResults.length > 0) {
            var objectIds = [];
            results.addFeatureResults.forEach((item) => {
              objectIds.push(item.objectId);
            });
            // query the newly added features from the layer
            layer.queryFeatures({
              objectIds: objectIds
            }).then((results) => {
              console.info(`Successfully added ${results.features.length} suppliers to the feature layer.`);
            });
          }
        }).catch((error) => {
          console.error(`Error while adding suppliers to the feature layer.`);
          console.error(error);
        });
      }
      
      const legend = new Legend({
        view: view
      });
      view.ui.add(legend, "bottom-left");

   
     
      const expand = new Expand({
        view: view,
        content: infoDiv.current,
        expanded: false
    });
  
    view.ui.add(expand, "top-right");

    function addSupplyChainPartnersToLayers(suppliersLayer, graphicsLayer, supplier) {
      const name = supplier.name.toLowerCase();
      const graphics = [];

      let partnerFound = false;
      this.supplyChain.forEach(item => {
        if (item.buyer.toLowerCase() === name || item.seller.toLowerCase() === name) {
          console.info(`Found a record in supply chain: ${item.buyer} - ${item.seller}`);
          partnerFound = true;
          supplier.partnershipRole = item.buyer.toLowerCase() === name ? "buyer" : "seller";
          let partner;
          if (supplier.partnershipRole === "buyer") {
            partner = this.getSupplierByName(item.seller);
            partner.partnershipRole = "seller";
          } else {
            partner = this.getSupplierByName(item.buyer);
            partner.partnershipRole = "buyer";
          }
          supplier.partnership = item;
          partner.partnership = item;

          graphics.push(this.createSupplierGraphic(partner));

          const partnershipGraphic = this.createPartnershipGraphic(supplier, partner);
          graphicsLayer.add(partnershipGraphic);
        }
      });
      // Add graphics to layer
      this.applyAddGraphicsToLayer(suppliersLayer, {
        addFeatures: graphics
      });
      return partnerFound;
    }


    }
  
       
  
  }, []);

 const filteredsupplier= suppliers.suppliers.map(function (value) {
    return {
        label: value.name,
        value: value.name
    }
})
const filteredcountry=countries.map(function(value){
  return{
    label: value.name,
    value: value.name
  }
})
const options = [...filteredsupplier, ...filteredcountry];




  return <><div className="mapDiv" ref={mapDiv}></div>
              <div className="infoDiv" ref={infoDiv}>
                <h4>Filter&nbsp;By&nbsp;Country&nbsp;and&nbsp;Factory:</h4>
                    <Select 
                    class="esri-select" 
                    options={options} id="myoptions"
                    isMulti
                    label="Shoe Drop Down"
                    subtitle="Make a selection:">
                   </Select>
               <br />
       
     </div></>;
}

export default SuppliersMap;