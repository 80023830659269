
import Graphic from "@arcgis/core/Graphic";

  function createSupplierGraphic(supplier) {
    return new Graphic({
      attributes: {
        supplier: supplier,
        supplierId: supplier.contractor_id,
        supplierName: supplier.name,
        productEngine: supplier.product_type,
        supplierCity: supplier.city,
        supplierCountry: supplier["country/region"],
        workers: supplier.workers_line,
        femaleWorkers: supplier.workers_female,
        migrantWorkers: supplier.workers_migrant,
        averageWorkerAge: supplier.average_worker_age,
        tier: supplier.factory_tier,
        brand: supplier.brand
      },
      geometry: {
        type: "point",
        longitude: supplier.lon,
        latitude: supplier.lat
      }
    });
  }

  function addSuppliersToLayer(layer, suppliers) {
    
    const graphics = suppliers.map(function (supplier) {
      return createSupplierGraphic(supplier);
    });
  
    // apply the edits to the layer
    applyAddGraphicsToLayer(layer, {
      addFeatures: graphics
    });
  }

  function applyAddGraphicsToLayer(layer, additions) {
    layer.applyEdits(additions).then(results => {
      if (results.addFeatureResults.length > 0) {
        var objectIds = [];
        results.addFeatureResults.forEach((item) => {
          objectIds.push(item.objectId);
        });
        // query the newly added features from the layer
        layer.queryFeatures({
          objectIds: objectIds
        }).then((results) => {
          console.info(`Successfully added ${results.features.length} suppliers to the feature layer.`);
        });
      }
    }).catch((error) => {
      console.error(`Error while adding suppliers to the feature layer.`);
      console.error(error);
    });
  }
 


export default addSuppliersToLayer